import {
  checkPrivilege,
  getLanguage,
  getDeviceName,
  getBrowserName,
} from "./../../utils/functions";
import {
  ESTABLISHMENT_TOKEN,
  USER_PLATFORM_SESSION_VERSION_NUMBER,
  DEFAULT_PAGES,
  STATUS,
} from "./../../utils/constants";
import UsersPrivilegeData from "./../../models/modulePrivileges/UsersPrivilegeData";

import axios from "axios";
import store from "./../../store";
import i18n from "./../../i18n";

export default class UserLogin {
  //#region UserLogin
  USER_PLATFORM_SESSION = {
    userPlatFormSessionVersionNumber: USER_PLATFORM_SESSION_VERSION_NUMBER,
    userPlatFormSessionLocalIP: "",
    userPlatFormSessionGlobalIP: "",
    userPlatFormSessionAddress: "",
    userPlatFormSessionLat: "",
    userPlatFormSessionLong: "",
    userPlatFormToken: "PFT-3",
    userFirebaseToken: "userFirebaseToken",
  };
  USER_DEVICE = {
    userDeviceUniqueCode: "WEB",
    userDeviceId: "",
    userDeviceName: "Unkonwn",
    userDeviceCompany: "",
    userDeviceOS: "",
    userDeviceVersionOS: getBrowserName() + " " + getDeviceName(),
    userDeviceEMUI: "",
    userDeviceRam: "",
    userDeviceProcessor: "",
    userDeviceDisplay: "",
    userDeviceDescription: "",
    userDeviceNotes: "",
  };
  USER_APP_SETTING = {
    languageToken: getLanguage(),
    themeToken: "",
    decimalFormatToken: "",
    timeZoneToken: "Egypt Standard Time",
    dateFormatToken: "dddd, dd MMMM yyyy",
    timeFormatToken: "hh:mm:ss tt",
    currencyToken: "EGP",
    printCodeTypeToken: "",
    startDayOfWeekToken: "",
    startMonthOfYear: 0,
    startDayOfMonth: 0,
    backAfterAddStatus: true,
    backAfterEditStatus: true,
    receiveNotificationStatus: true,
    customSettings: "",
  };
  //#endregion UserLogin

  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.user = "";
    this.userPassword = "";
    this.establishmentToken = ESTABLISHMENT_TOKEN;
    this.userPlatformSessionData = this.USER_PLATFORM_SESSION;
    this.userDeviceData = this.USER_DEVICE;
    this.userAppSettingData = this.USER_APP_SETTING;
  }
  fillData(data) {
    this.language = data.language;
    this.user = data.user;
    this.userPassword = data.userPassword;
    this.establishmentToken = data.establishmentToken;
    this.userPlatformSessionData = data.userPlatformSessionData;
    this.userDeviceData = data.userDeviceData;
    this.userAppSettingData = data.userAppSettingData;
  }

  async login() {
    let response = await axios.post(`/api/UsersAuthentication/UserLogin`, this);
    return response;
  }

  saveUserData(apiAppData) {
    let userHomePage = DEFAULT_PAGES.notLoggedIn;

    const userPersonalDataObject = {
      userEmail: apiAppData.userData.userEmail,
      userImagePath: apiAppData.userData.userImagePath,
      userName: apiAppData.userData.userName,
      userNameAr: apiAppData.userData.userNameAr,
      userNameCurrent: apiAppData.userData.userNameCurrent,
      userNameEn: apiAppData.userData.userNameEn,
      userNameUnd: apiAppData.userData.userNameUnd,
      userPassword: apiAppData.userData.userPassword,
      userPhone: apiAppData.userData.userPhone,
      userPhoneCountryCode: apiAppData.userData.userPhoneCountryCode,
      userPhoneCountryCodeName: apiAppData.userData.userPhoneCountryCodeName,
      userPhoneWithCC: apiAppData.userData.userPhoneWithCC,
      establishmentRoleData: apiAppData.userData.establishmentRoleData,
      userToken: apiAppData.userData.userToken,
      userTypeNameAr: apiAppData.userData.userTypeNameAr,
      userTypeNameCurrent: apiAppData.userData.userTypeNameCurrent,
      userTypeNameEn: apiAppData.userData.userTypeNameEn,
      userTypeNameUnd: apiAppData.userData.userTypeNameUnd,
      userTypeToken: apiAppData.userData.userTypeToken,
      activationTypeToken: apiAppData.userData.activationTypeToken,
    };

    let response = {
      status: STATUS.ERROR,
      msg: i18n.t("sorryYouDoNotHaveThisPermission"),
    };

    let privileges = new UsersPrivilegeData();
    privileges.fillData(
      apiAppData.userData.establishmentRoleData.modulePrivilegeData
    );

    // #region userHomePage
    if (checkPrivilege(false, apiAppData.userData.userTypeToken)) {
      userHomePage = DEFAULT_PAGES.loggedIn;
    } else if (privileges.News.view) {
      userHomePage = "Newss";
    } else if (privileges.EducationalPeriod.view) {
      userHomePage = "EducationalPeriods";
    } else if (privileges.EducationalCategory.view) {
      userHomePage = "EducationalCategories";
    } else if (privileges.EducationalRating.view) {
      userHomePage = "EducationalCategoriesRatings";
    } else if (privileges.EducationalGroup.view) {
      userHomePage = "EducationalGroups";
    } else if (privileges.EducationalScheduleTime.view) {
      userHomePage = "EducationalScheduleTimes";
    } else if (privileges.EducationalGroupAttendance.view) {
      userHomePage = "EducationalGroupAttendances";
    } else if (privileges.SimpleExamModel.view) {
      userHomePage = "SimpleExamModels";
    } else if (privileges.GroupScheduleExam.view) {
      userHomePage = "GroupScheduleExams";
    } else if (privileges.StudentScheduleExamTime.view) {
      userHomePage = "StudentScheduleExamTimes";
    } else if (privileges.EducationalJoiningApplication.view) {
      userHomePage = "EducationalJoiningApplications";
    } else if (privileges.AdminsEgypt.view) {
      userHomePage = "AdminsEgypt";
    } else if (privileges.AdminsEgypt.view) {
      userHomePage = "MasterAdmin";
    } else if (privileges.Employee.view) {
      userHomePage = "Employees";
    } else if (privileges.Student.view) {
      userHomePage = "Students";
    } else if (privileges.Place.view) {
      userHomePage = "Places";
    } else if (privileges.Service.view) {
      userHomePage = "Services";
    } else if (privileges.Complaint.view) {
      userHomePage = "Complaints";
    } else if (privileges.Account.view) {
      userHomePage = "Accounts";
    } else if (privileges.AccountTransaction.view) {
      userHomePage = "AccountTransactions";
    } else if (privileges.Expenses.view) {
      userHomePage = "Expenses";
    } else if (privileges.Revenues.view) {
      userHomePage = "Revenues";
    } else if (privileges.Debt.view) {
      userHomePage = "Debts";
    } else if (privileges.Installment.view) {
      userHomePage = "Installments";
    } else if (privileges.InstallmentPayment.view) {
      userHomePage = "InstallmentPayments";
    } else if (privileges.ServiceEntitlement.view) {
      userHomePage = "ServiceEntitlements";
    } else if (privileges.InterestFollowUp.view) {
      userHomePage = "InterestFollowUps";
    } else if (
      privileges.MasterAdminEstablishmentsRole.view ||
      privileges.EmployeeEstablishmentsRole.view ||
      privileges.StudentEstablishmentsRole.view
    ) {
      userHomePage = "EstablishmentRoles";
    } else if (
      privileges.MasterAdminValidSetting.view ||
      privileges.EmployeeValidSetting.view ||
      privileges.StudentValidSetting.view
    ) {
      userHomePage = "UserValidSettings";
    } else if (
      privileges.SystemComponentPlace.view ||
      privileges.SystemComponentEducationalActivity.view ||
      privileges.SystemComponentGeographicalDistribution.view ||
      privileges.SystemComponentJobGrade.view ||
      privileges.SystemComponentStoreItem.view
    ) {
      userHomePage = "SystemComponents";
    } else if (
      privileges.SystemComponentsHierarchyPlace.view ||
      privileges.SystemComponentsHierarchyEducationalActivity.view ||
      privileges.SystemComponentsHierarchyGeographicalDistribution.view ||
      privileges.SystemComponentsHierarchyJobGrade.view ||
      privileges.SystemComponentsHierarchyStoreItem.view
    ) {
      userHomePage = "SystemComponentsHierarchies";
    } else if (
      privileges.Qualification.view ||
      privileges.MilitaryStatusType.view ||
      privileges.BloodType.view ||
      privileges.Certificate.view ||
      privileges.SocialStatuse.view ||
      privileges.Bank.view ||
      privileges.GeographicalDistribution.view ||
      privileges.InsuranceCompany.view ||
      privileges.Color.view ||
      privileges.PriorityType.view ||
      privileges.ComplaintType.view ||
      privileges.NewsCategory.view ||
      privileges.ComplaintSector.view ||
      privileges.ReminderPlan.view ||
      privileges.ExpensesType.view ||
      privileges.RevenuesType.view ||
      privileges.PaymentMethod.view ||
      privileges.GradingPlan.view ||
      privileges.PriceList.view ||
      privileges.ServiceType.view
    ) {
      userHomePage = "SettingsOthers";
    } else {
      userHomePage = DEFAULT_PAGES.notLoggedIn;
    }

    // #endregion userHomePage

    // setUserHomePage
    store.dispatch("setUserHomePage", userHomePage);

    if (userHomePage != DEFAULT_PAGES.notLoggedIn) {
      let data = {
        userAuthorizeToken: apiAppData.userAuthorizeToken,
        userPersonalDataObject,
        constantsListsData: apiAppData.constantsListsData,
        usersPrivilegeData: privileges,
      };

      // login
      store.dispatch("loginUser", data);

      response.status = STATUS.SUCCESS;
      response.msg = i18n.t("successOperation");
    }
    return response;
  }
}
